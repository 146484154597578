import request from "@/utils/request";

// 顶部轮播图
export function listCustomizedPlan() {
  return request.get("/goods/customized/listCustomizedPlan");
}

// 中间内容轮播图
export function listCustomizedConfig(id) {
  return request.get(`/goods/customized/listCustomizedConfig/${id}`);
}
// 选择物料弹窗
export function listSupportMaterial(id) {
  return request.get(`/goods/customized/listSupportMaterial/${id}`);
}

export function checkCustomizedMaterial(data) {
  return request.get("/goods/customized/pcCheckCustomizedMaterial?id=" + data.id + "&checkState=" + data.checkState + '&configPrice=' + data.configPrice);
}

export function getCustomizedMaterialById(configId) {
  return request.get(`/goods/customized/getCustomizedMaterialById/${configId}`);
}
export function pcCustomizedMaterialById(id) {
  return request.get(`/goods/customized/pcCustomizedMaterialById/${id}`);
}
// 定制提交
export function PostCustomizedOrder(data) {
  return request.post("/order/order/saveCustomizedOrder", data);
}
export function pcSaveCustomizedMaterial(data) {
  return request.post("/goods/customized/pcSaveCustomizedMaterial", data);
}
